.frame-temp{
    /* width: 384px;
    height: 512px; */
    width: 0px;
    /* border: 1px solid black; */
  }

/* img {
  image-rendering: crisp-edges;
} */

.display--flex{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.margin--10px{
  margin: 10px 10px;
}

.margin_right{
  width: 140px;
}

.container{
  position: relative;
  width: 600px;
  height: 800px;
}

.item{
  position: absolute;
}